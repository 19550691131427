import React from 'react';
import Navigation from 'components2/Navigation';
import Layout from 'components2/Layout';
import Button from 'components2/Button';
import DrawSvg from 'components2/DrawSvg';
import ScaleIn from 'components2/ScaleIn';
import SubFooter from 'components2/SubFooter';
import Carousel from 'components2/Carousel';
import { styled } from 'style2';

import usePlatform from 'hooks/usePlatform';
import useIsVisible from 'hooks/useIsVisible';

import discoElysium from 'images/box-art/discoElysium.jpg';
import divinity from 'images/box-art/divinity.jpg';
import dragonBallFighterZ from 'images/box-art/dragonBallFighterZ.jpg';
import efootballPes2020 from 'images/box-art/efootballPes2020.jpg';
import enterTheGungeon from 'images/box-art/enterTheGungeon.jpg';
import streetsOfRage from 'images/box-art/streetsOfRage.jpg';
import cuphead from 'images/box-art/cuphead.jpg';
import hades from 'images/box-art/hades.jpg';
import heaveHo from 'images/box-art/heaveHo.jpg';
import lethalLeagueBlaze from 'images/box-art/lethalLeagueBlaze.jpg';
import spelunky2 from 'images/box-art/spelunky2.jpg';
import unrailed from 'images/box-art/unrailed.jpg';
import spiritFarer from 'images/box-art/spiritFarer.jpg';
import movingOut from 'images/box-art/movingOut.jpg';
import nPlusPlus from 'images/box-art/nPlusPlus.jpg';
import towerFallAscension from 'images/box-art/towerFallAscension.jpg';
import untitledGooseGame from 'images/box-art/untitledGooseGame.jpg';
import onlineCouchGaming from 'images/local-co-op-online/onlineCouchGaming.svg';
import parsecSdk from 'images/local-co-op-online/parsecSdk.svg';
import globalArcade from 'images/local-co-op-online/globalArcade.svg';
import hills from 'images/local-co-op-online/hills.png';
import hills2x from 'images/local-co-op-online/hills@2x.png';
import circle from 'images/local-co-op-online/circleWhite.svg';
import ex from 'images/local-co-op-online/exWhite.svg';
import star from 'images/local-co-op-online/starWhite.svg';
import { DOWNLOADS } from 'config';

const carouselItems = {
  hades: { name: 'Hades', image: hades },
  lethalLeagueBlaze: { name: 'Lethal League: Blaze', image: lethalLeagueBlaze },
  unrailed: { name: 'Unrailed!', image: unrailed },
  spelunky2: { name: 'Spelunky 2', image: spelunky2 },
  spiritFarer: { name: 'Spiritfarer', image: spiritFarer },
  cuphead: { name: 'Cuphead', image: cuphead },
  untitledGooseGame: { name: 'Untitled Goose Game', image: untitledGooseGame },
  heaveHo: { name: 'Heave Ho', image: heaveHo },
  nPlusPlus: { name: 'N++', image: nPlusPlus },
  movingOut: { name: 'Moving Out', image: movingOut },
  towerFallAscension: {
    name: 'Towerfall: Ascension',
    image: towerFallAscension
  },
  discoElysium: { name: 'Disco Elysium', image: discoElysium },
  divinity: { name: 'Divinity Original Sin I', image: divinity },
  dragonBallFighterZ: {
    name: 'Dragon Ball FighterZ',
    image: dragonBallFighterZ
  },
  enterTheGungeon: { name: 'Enter The Gungeon', image: enterTheGungeon },
  efootballPes2020: { name: 'Football PES2020', image: efootballPes2020 },
  streetsOfRage: { name: 'Streets Of Rage 4', image: streetsOfRage }
};

export default function LocalCoOpOnline() {
  return (
    <Layout
      title="Play Multiplayer Games Online"
      description="Play multiplayer video games online with friends and people all over the world in the Parsec Arcade using Parsec’s remote game streaming software."
    >
      <Header>
        <Navigation color="light" />
        <HeaderText>
          <h1>
            Play Your Favorite Games <LineBreak /> with Your Friends Online
          </h1>
          <p>
            Any multiplayer game in the world can be played online with Parsec.
            <LineBreak /> Download Parsec, share a link with a friend, and they
            can join in a click.
          </p>
        </HeaderText>

        <section>
          <Carousel items={carouselItems} />
        </section>
      </Header>

      <MidBanner>
        <MidHeaderText>
          Parsec connects you to the games <LineBreak /> & people you love,
          wherever you are.
        </MidHeaderText>
        <MidBoxes>
          <IndividualBox>
            <BoxImageWrapper>
              <img
                src={onlineCouchGaming}
                width="98.556"
                height="83.189"
                alt=""
              />
            </BoxImageWrapper>
            <BoxHeader>Online Couch Gaming</BoxHeader>
            <p>
              Forget about subscriptions and platforms. Parsec is built to play
              games with friends online in the simplest way possible. Just send
              your friend a link, and they can start playing any of your games
              with you on any computer or phone instantly.
            </p>
          </IndividualBox>
          <IndividualBox>
            <BoxImageWrapper>
              <img src={globalArcade} width="98.556" height="83.189" alt="" />
            </BoxImageWrapper>
            <BoxHeader>Global Arcade</BoxHeader>
            <p>
              Play, share, discover new games for free. Jump into the world’s
              largest arcade, meet new people, and discover new games. All you
              need is an internet connection, and you can play games with
              friends or strangers all over the world.
            </p>
          </IndividualBox>
          <IndividualBox>
            <BoxImageWrapper>
              <img src={parsecSdk} width="98.927" height="79" alt="" />
            </BoxImageWrapper>
            <BoxHeader>Parsec SDK</BoxHeader>
            <p>
              The <a href='/sdk'>game developer SDK</a> makes your game online ready with a
              simple, light-weight integration. Using our industry leading game
              streaming technology, we’ve eliminated the need for servers and
              netcode to make your game instantly cross platform and shareable.
            </p>
          </IndividualBox>
        </MidBoxes>
      </MidBanner>

      <Footer />
    </Layout>
  );
}

function Footer() {
  const platform = usePlatform();
  const [ref, visible] = useIsVisible<HTMLDivElement>({
    threshold: 0.1
  });

  return (
    <SubFooter
      ref={ref}
      title="See it for yourself. Download for free and try Parsec."
      actions={
        <Button href={(platform && DOWNLOADS[platform]) || '/downloads'}>
          Download Now
        </Button>
      }
      backgroundColor="#f62e6a"
      backgroundImage={hills}
      backgroundImageRetina={hills2x}
      backgroundSize="75rem"
      backgroundPosition="bottom -16rem right"
    >
      <DrawSvg visible={visible}>
        <Svg
          css={{ bottom: '33.8rem', right: '52.6rem' }}
          width="81"
          height="73"
          viewBox="0 0 81 73"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M.477 32.83C4.051 21.406 11.804 12.531 23.535 9.505c22.182-5.722 46.295 9.647 54.46 30.253 3.457 8.723 3.444 19.682-2.068 27.6-3.722 5.346-9.836 6.773-15.46 3.432a23.36 23.36 0 01-7.059-7.089 45.461 45.461 0 01-6.175-37.69C50.819 13.424 60.052 1.82 74.053 1.255"
            stroke="#FFF"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </Svg>
      </DrawSvg>

      <Scale
        css={{ bottom: '43.4rem', right: '49.9rem' }}
        visible={visible}
        delay={750}
      >
        <img src={star} alt="" />
      </Scale>

      <Scale
        css={{ bottom: '32.7rem', right: '59rem' }}
        visible={visible}
        delay={900}
      >
        <img src={circle} alt="" />
      </Scale>

      <Scale
        css={{ bottom: '43.4rem', right: '25.1rem' }}
        visible={visible}
        delay={1050}
      >
        <img src={circle} alt="" />
      </Scale>

      <Scale
        css={{ bottom: '37.6rem', right: '7.8rem' }}
        visible={visible}
        delay={1200}
      >
        <img src={star} alt="" />
      </Scale>

      <Scale
        css={{ bottom: '43.9rem', right: '6.3rem' }}
        visible={visible}
        delay={1350}
      >
        <img src={ex} alt="" />
      </Scale>
    </SubFooter>
  );
}

const Header = styled('header', {
  backgroundColor: '$pitchBlack',
  paddingBottom: '16rem',
  overflowX: 'hidden'
});

const HeaderText = styled('div', {
  display: 'grid',
  rowGap: '2rem',
  textAlign: 'center',
  margin: '15rem 4rem 0',
  color: '$consoleWhite'
});

const MidBanner = styled('div', {
  padding: '12rem 0 16rem',
  backgroundColor: '$computerBlack'
});

const LineBreak = styled('br', {
  large: {
    display: 'block'
  },
  display: 'none'
});

const MidHeaderText = styled('h2', {
  textAlign: 'center',
  fontSize: '$h2',
  lineHeight: '$h2',
  fontWeight: 'bold',
  margin: '0 4rem',
  color: '$consoleWhite'
});

const MidBoxes = styled('ul', {
  margin: '8rem 4rem 0',
  display: 'grid',
  gap: '2rem',
  justifyContent: 'center',
  full: {
    gridTemplateColumns: 'repeat(3, 38.8rem)'
  }
});

const IndividualBox = styled('li', {
  backgroundColor: '$carkol',
  borderRadius: '$medium',
  textAlign: 'center',
  color: '$rhyhorn',
  padding: '5rem 2.5rem',
  maxWidth: '59.2rem',
  full: {
    maxWidth: '38.8rem'
  }
});

const BoxHeader = styled('h3', {
  fontWeight: 'bold',
  color: '$consoleWhite',
  marginBottom: '2rem'
});

const BoxImageWrapper = styled('div', {
  height: '9.2rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  marginBottom: '4rem'
});

const Svg = styled('svg', {
  position: 'absolute'
});

const Scale = styled(ScaleIn, {
  position: 'absolute'
});
